import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PageCreditoHipotecario = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Credito Hipotecario' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Stack>
        
      </Stack>
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='¡Volvío el Crédito Hipotecario!: Una Oportunidad Histórica para Acceder a la Vivienda Propia en Argentina. 🏡'
          subheader=' El Banco Hipotecario acaba de lanzar una nueva línea de créditos hipotecarios. 🔑'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/tarjeta-mastercard">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Mastercard 💳</Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Tarjeta de Crédito BBVA 🤑 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Visa Platinium 🤑 </Button>
        </Link>
      </div>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          Pidiendo USD 40.000, arrancás en una cuota mensual de AR$ 202.400 (y necesitás un ingreso de AR$ 809.600). Con ese crédito podés comprar una propiedad de USD 50.000 o más. Si comprases una de 50k, los gastos asociados a la transacción rondarían los USD 4.500; por lo que necesitarías tener USD 14.500 ahorrados.
          <br><br>
Pidiendo USD 100.000, arrancás en una cuota mensual de AR$ 505.000 (y necesitás un ingreso de AR$ 2.020.000). Con ese crédito podés comprar una propiedad de USD 125.000 o más. Si comprases una de 125k, los gastos asociados a la transacción rondarían los USD 11.250; por lo que necesitarías tener USD 36.250 ahorrados.
<br><br>
Pidiendo USD 250.000 (el monto máximo de la línea), arrancás en una cuota de AR$ 1.262.500 (y necesitás un ingreso de AR$ 5.050.000). Con ese crédito podés comprar una propiedad de USD 312.500 o más. Si comprases una de 312.500, los gastos asociados a la transacción rondarían los USD 28.125; por lo que necesitarías tener USD 90.625 ahorrados.
<br><br>
Hoy con USD 50.000 podés comprarte un 2 amb en San Cristóbal o Boedo, con vista abierta y buena circulación de aire. En un edificio civilizado, y en un estado regular (muy vivible).

          

          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         
          Después de cinco años de casi no tener crédito hipotecario en la Argentina, un banco lanza una propuesta en UVA
          <br><br>
Estos créditos hipotecarios UVA tienen una tasa de 8,65% final. Para aquellos que sean clientes del banco y acrediten haberes ahí tiene un beneficio del 50% de la cuota durante el primer año, es decir 4,4% final por 12 meses. Se lanza oficialmente al mercado durante el mes de mayo, aún con fecha a confirmar, y para algunos expertos se trata de un renacer de los años 2016 a 2018, donde surgió el crédito UVA y fueron muchos los que se lanzaron a solicitar este préstamo.
<br><br>
El fenómeno se da en un contexto en el que prácticamente no existe oferta de crédito hipotecario en la Argentina. “Es el primero que está saliendo después de mucho tiempo de que casi no haya crédito. Existía, pero había poco. El Banco de Córdoba venía con algo, pero era muy específico para esa provincia. Por otro lado, muchos bancos eran muy reacios a dar crédito hipotecario, ya que estaba muy latente la posibilidad de que el gobierno de Alberto Fernández modificara las condiciones, algo que se discutía cada ciertos meses”, señala Federico González Rouco, economista de la consultora Empiria, que lidera Hernán Lacunza, ex ministro de Economía.
<br><br>
<b>Los requisitos para acceder al crédito hipotecario 2024
Para comprar casa los requisitos que el Banco Hipotecario pone como condición para solicitar este crédito son los siguientes:</b>
<br><br>
- El crédito es por hasta $250 millones en un único desembolso.<br>
- Financian hasta el 80% de la vivienda. Quien lo solicita debe contar con el 20% restante, que se pone de anticipo.<br>
- El crédito se debe destinar a primera o segunda vivienda.<br>
- Tiene una tasa de 8,5%. Las primeras 12 cuotas para los clientes con acreditación de haberes en el banco tienen una tasa de 4,25%, lo que significa una promoción del 50% en el primer año (con el costo financiero total: tasa de 8,65% final; 4,4% final el primer año para cuentas sueldo).<br>
- El plazo máximo de pago es de 360 meses (30 años).<br>
- El capital es ajustable por UVA.<br>
- La cuota mensual a pagar no puede exceder el 25% del ingreso del solicitante.<br>

          

          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://anses.blog/tarjeta-santander/" style={{ textDecoration:'none' }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'black'}}><span style={{fontWeight:'700'}}>También puede interesarte: </span>Sacá tu tarjeta Visa Platinium con hasta $1.200.000 de limite. No importa el veraz</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         
          Para ejemplificar, Rouco habla de que por cada US$50.000 de préstamo (no de valor de la vivienda), se solicitarán 25 años de plazo, cuotas de $400.000 y hacen falta ingresos en blanco de $1,6 millones del hogar deudor. En el caso de una pareja (hogar deudor) se pueden sumar los ingresos y solicitar el crédito en conjunto y, por tanto, pedir más dinero. En ese caso, “para comprar algo de US$125.000, se necesitan ingresos de $3,2 millones, aproximadamente. Estamos hablando de a hoy de cuatro salarios promedio”.
          <br><br>
          Además de que esta línea de crédito se puede solicitar para compra de primera o segunda vivienda, también se otorga para construcción, terminación y ampliación de viviendas.
          <br><br>
          <b>Para construcción de una casa</b><br>
          - El crédito es por hasta $250 millones con un anticipo y dos desembolsos contra avance de la obra.<br>
          - Financian hasta el 80% del presupuesto de obra.<br>
          - El crédito se debe destinar a la construcción de primera o segunda vivienda.<br>
          - Tiene una tasa de 8,5%. Las primeras 12 cuotas para los clientes con acreditación de haberes en el banco tienen una tasa de 4,25%, lo que significa una promoción del 50% en el primer año (con el costo financiero total: tasa de 8,65% final; 4,4% final el primer año para cuentas sueldo).<br>
          - El plazo máximo de pago es de 360 meses (30 años).<br>
          - El capital es ajustable por UVA.<br>
          - La cuota mensual a pagar no puede exceder el 25% del ingreso del solicitante.<br>
          <br><br>
          <b>Para terminación de una casa</b><br>
          El crédito es por hasta $125 millones en un único desembolso.<br>
          - Financian hasta el 50% del presupuesto de obra.<br>
          - El crédito se debe destinar a la terminación de primera o segunda vivienda.<br>
          - Tiene una tasa de 8,5%. Las primeras 12 cuotas para los clientes con acreditación de haberes en el banco tienen una tasa de 4,25%, lo que significa una promoción del 50% en el primer año (con el costo financiero total: tasa de 8,65% final; 4,4% final el primer año para cuentas sueldo).<br>
          - El plazo máximo de pago es de 360 meses (30 años).<br>
          - El capital es ajustable por UVA.<br>
          - La cuota mensual a pagar no puede exceder el 25% del ingreso del solicitante.<br>
          <br><br>
          <b>Para ampliación de una casa</b><br>
          El crédito es por hasta $125 millones en un único desembolso.<br>
          - Financian hasta el 100% del presupuesto sin superar el 35% del valor de la garantía actual.<br>
          - El crédito se debe destinar a la ampliación de primera o segunda vivienda.<br>
          - Tiene una tasa de 8,5%. Las primeras 12 cuotas para los clientes con acreditación de haberes en el banco tienen una tasa de 4,25%, lo que significa una promoción del 50% en el primer año (con el costo financiero total: tasa de 8,65% final; 4,4% final el primer año para cuentas sueldo).<br>
          - El plazo máximo de pago es de 360 meses (30 años).<br>
          - El capital es ajustable por UVA.<br>
          - La cuota mensual a pagar no puede exceder el 25% del ingreso del solicitante.<br>
          
          

          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://anses.blog/tarjeta-santander/" style={{ textDecoration:'none' }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'black'}}><span style={{fontWeight:'700'}}>También puede interesarte: </span>Sacá tu tarjeta Visa Platinium con hasta $1.200.000 de limite. No importa el veraz</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         
        
          Un ejemplo de cuota para clientes y no clientes del Banco Hipotecario sería así: cada $1.000.000 a 30 años, quienes sean clientes y acrediten allí su sueldo pagarán una cuota de $5050 con una tasa preferencial de 4,25% el primer año; quienes no sean clientes del banco pagarán una cuota de $7820 en ese mismo período.
          <br><br>
“Financian hasta el 80% del valor en el caso de la compra, cuando generalmente es el 75%, por lo que la barrera de acceso es más baja. Sigue siendo mucho, pero significa menos que en otras oportunidades”, explica Rouco y, en cuanto a los ingresos que se piden para poder solicitar el crédito señala: “Son niveles cercanos a los que alguien de clase media o media alta podría acceder”.
<br><br>
A la consulta de si otros bancos incursionarán también en la posibilidad de ofrecer un crédito hipotecario, la respuesta de Rozados es que probablemente lo harán. “Seguramente será un puntapié inicial que obligará a otros bancos a acelerar el proceso, para no perder cuota del mercado de clientes”, señala Rozados y agrega: “Los bancos utilizan el crédito hipotecario como un ancla para tener clientes cautivos a largo plazo, ya que es una manera de asegurarlos por 10, 15 o 20 años, y también para captar nuevos”.
<br><br>
<b>El crédito hipotecario en la Argentina</b><br>
Si se analizan los números de crédito hipotecario en nuestro país, hoy es prácticamente nulo y ha mantenido siempre cifras muy bajas. En el mejor momento, que tuvo lugar en el 2001, la masa de crédito hipotecario representaba el 5% del PBI, es decir que, toda la deuda que tenía el país representaba el 5% de lo que se produce en un año en la Argentina.
<br><br>
Esto es <b>OPORTUNIDAD HISTÓRICA</b> para el trabajador de clase media que quiere su casa propia. Las mayorías, al comienzo, se asustarán frente al UVA + 8,5% (del año 2 en adelante). Lo que no ven es que los precios de las propiedades ya están corriendo al +18% semestral (casi 40% anual) en los barrios consolidados de CABA (Recoleta, Palermo, Belgrano).


          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://anses.blog/tarjeta-mastercard/" style={{ textDecoration:'none' }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'black'}}><span style={{fontWeight:'700'}}>También puede interesarte: </span>Sacá tu tarjeta Mastercard del Santander. No importa el veraz</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageCreditoHipotecario
